<template>
  <div>
    <van-sticky>
      <title-bar2 title="在线支付" />
    </van-sticky>
    <van-cell-group title="订单信息">
      <van-card :thumb="require('@/assets/images/mt/thumb.png')" :centered="false" :title="orderName" :price="`${orderAmount.toFixed(2)}`" :num="1" />
      <van-cell title="支付方式：" value="在线支付" />
      <van-cell title="商品金额：" :value="orderAmount.toFixed(2)" />
    </van-cell-group>
    <van-cell-group title="付款方式" v-if="payWay == 1 || payWay == 2">
      <van-radio-group v-model="payWay">
        <van-cell clickable @click="payWay = 1">
          <template #title>
            <span class="radio-item">
              <img src="@/assets/images/comm/pay-alipay.png" class="radio-img">
              <span class="radio-txt">支付宝支付</span>
            </span>
          </template>
          <template #right-icon>
            <van-radio :name="1" />
          </template>
        </van-cell>
        <!-- <van-cell clickable @click="payWay = 2">
          <template #title>
            <span class="radio-item">
              <img src="@/assets/images/comm/pay-wechat.png" class="radio-img">
              <span class="radio-txt">微信支付</span>
            </span>
          </template>
          <template #right-icon>
            <van-radio :name="2" />
          </template>
        </van-cell> -->
      </van-radio-group>
    </van-cell-group>
    <van-notice-bar wrapable :scrollable="false" left-icon="info-o">请务必仔细核对订单，确认信息无误后再支付，支付后不支持退款！</van-notice-bar>
    <van-submit-bar :disabled="disabled" :price="orderAmount * 100" button-text="提交订单" @submit="onSubmit" />
  </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import Manager from "@/manager";
import Tools from "@/tool";
import Config from "@/api/service";

export default {
  name: "MTModule",
  components: {
    TitleBar2,
  },
  data() {
    return {
      disabled: false,
      isDocin: false,
      productGuid: null,
      orderName: null,
      orderAmount: 0,
      payWay: 0,
    };
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      this.productGuid = this.$route.query.productGuid;
    },
    //获取是否豆丁账户
    getIsDocin() {
      var model = {
        ProxyCode: this.$store.state.proxyCode,
      };
      return this.$axios
        .post(Config.proxy.isDocin, model)
        .then((res) => {
          this.isDocin = res.data.Data.IsDocin;
          if (!this.isDocin) {
            this.payWay = 1;
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //查询商品信息
    getProductInfo() {
      var model = {
        DocumentGuid: this.productGuid,
      };
      this.$axios
        .post(Config.document.info, model)
        .then((res) => {
          this.orderName = res.data.Data.DocumentName;
          this.orderAmount = res.data.Data.DocumentPrice;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //添加访问学校记录
    addVisitSchoolRecord() {
      Manager.addVisitSchoolRecord({
        DocumentGuid: this.productGuid,
        RecordModule: 3,
      });
    },
    //提交订单
    onSubmit() {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
        return;
      }
      if (Tools.isEmpty(this.payWay)) {
        this.$toast.fail("请选择付款方式。");
        return;
      }
      this.$dialog.confirm({
        title: '订单确认',
        message: '请确认订单信息，付款后不支持退款。',
      }).then(() => {
        this.disabled = true;
        var model = {
          ProxyCode: this.$store.state.proxyCode,
          ProductGuid: this.productGuid,
          TerminalType: 1,
          OrderPayForm: this.payWay,
          PayFinishUrl: Manager.goPayFinishUrl(this.$store.state.proxyCode),
        };
        this.$axios
          .post(Config.document.submitOrder, model)
          .then((res) => {
            this.addVisitSchoolRecord();
            this.$router.push({
              name: "PayBefore",
              params: {
                proxyCode: this.$store.state.proxyCode
              },
              query: {
                payUrl: res.data.Data.DocinPayUrl
              }
            });
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }).catch(() => {

      });
    }
  },
  mounted() {
    this.getRoutParams();
    this.getIsDocin();
    this.getProductInfo();
  }
};
</script>

<style scoped lang="less">
.radio-item {
  display: flex;
  align-items: center;

  .radio-img {
    width: 20px;
  }

  .radio-txt {
    margin-left: 10px;
  }
}
</style>